import React, { useState, useEffect, useContext} from 'react';
import './TopBar.css';
import { useTranslation } from 'react-i18next';
import LanguageContext from './LanguageContext';

const languages = [
    { code: 'en', name: 'English', flag: 'https://image.pfpframe.com/web-element/flags/us.svg' },
    { code: 'tr', name: 'Türkçe', flag: 'https://image.pfpframe.com/web-element/flags/tr.svg' }
];

const TopBar = () => {
    const { i18n, t } = useTranslation();
    const browserLanguage = navigator.language.slice(0, 2);
    const defaultLanguage = languages.find(lang => lang.code === browserLanguage) || languages[0];

    const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage);

    const { setLanguage } = useContext(LanguageContext);

    useEffect(() => {
        setSelectedLanguage(defaultLanguage);
        i18n.changeLanguage(defaultLanguage.code);
        setLanguage(defaultLanguage.code);
    }, [defaultLanguage]);


    const handleLanguageChange = (language) => {
        setSelectedLanguage(language);
        i18n.changeLanguage(language.code);
        setLanguage(language.code);
    };

    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className={`navbar ${scrollPosition > 50 ? 'scrolled' : ''}`}>
            <div className='navbar-inside'>
                <div className="navbar-brand">
                    <img src="logo512.png" alt="Logo" className="logo" />
                    <div className='text'>PFP Frame</div>
                </div>
                <div className="language-selector">
                    <div className="selected-language">
                        <img src={selectedLanguage.flag} alt={`${selectedLanguage.name} Flag SVG`} className='language-flags-top-bar'/>
                    </div>
                    <div className="dropdown">
                        {languages.map(language => (
                        <div 
                            key={language.code} 
                            className="dropdown-item" 
                            onClick={() => handleLanguageChange(language)}
                        >
                            <img src={language.flag} alt={`${language.name} Flag SVG`} className='language-flags-top-bar' />
                        </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TopBar;
