import React, { useState, useEffect, useRef, useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import './framePopup.css';
import "./frameList.css";

const FrameList = ({ frames, handleFrameClick, value }) => {
  const [items, setItems] = useState(frames.matchData || []);
  const [hasMore, setHasMore] = useState(true);
  const [index, setIndex] = useState(frames.end || 0);

  const { t, i18n } = useTranslation();
  
  useEffect(() => {
    setItems(frames.matchData);
    setIndex(frames.end);
    setHasMore(true);
  }, [frames]);

  const isFetching = useRef(false);

  const fetchMoreData = useCallback(async () => {
    if (isFetching.current) return;

    isFetching.current = true;
    try {
      const response = await axios.post('https://api.pfpframe.com/api/frame/get', { start: index, search: value || "" });
      console.log("Send Post:", index, value || "");
      if (response.data.status === 1 && response.data.matchData.length > 0 && response.data.end) {
        setItems((prevItems) => [...prevItems, ...response.data.matchData]);
        setIndex(response.data.end);
        setHasMore(true);
      } else {
        setIndex(response.data.end);
        setHasMore(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setHasMore(false);
    } finally {
      isFetching.current = false;
    }
  }, [index, value]);

  const scrollableDivRef = useRef(null);
  const isDivVisible = useRef(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        isDivVisible.current = entry.isIntersecting;
        if (isDivVisible.current) {
          if (scrollableDivRef.current) {
            const { scrollHeight, clientHeight } = scrollableDivRef.current;
            if (scrollHeight <= clientHeight && hasMore) {
              fetchMoreData();
            }
          }
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      }
    );

    if (scrollableDivRef.current) {
      observer.observe(scrollableDivRef.current);
    }

    return () => {
      if (scrollableDivRef.current) {
        observer.unobserve(scrollableDivRef.current);
      }
    };
  }, [items, hasMore, fetchMoreData]);
  
  return (
    <div id="scrollableDiv" tabIndex={-1} style={{ width:"100%", height: "100%",  overflowY: 'auto', overflowX: 'hidden', display: 'flex' }} ref={scrollableDivRef}>
      <InfiniteScroll
        className="infinite-scroll"
        dataLength={items.length}
        next={() => fetchMoreData()}
        hasMore={hasMore}
        loader={<div className='frame-list-loading-div'><div className="frame-list-loading"></div></div>}
        endMessage={
          <div className="frame-list-end">
            <div className='frame-list-end-image'>
              <img src="https://image.pfpframe.com/web-element/frame-list-end-image.png"
                alt="Frame List End Image"
              />
            </div>
            <div className='frame-list-end-text'>
              {t("frame-list-end-text-1")}
            </div>
            <div className='frame-list-end-text'>
              {t("frame-list-end-text-2")}
            </div>
          </div>
        }
        scrollableTarget="scrollableDiv"
      >
        <div className='frame-list'>
          {items.map((frame, index) => (
            <div
              key={index}
              className='frame-item'
              onClick={() => handleFrameClick(frame)}
            >
              <img src={frame} alt={`Frame ${index + 1}`} />
            </div>
          ))}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default FrameList;
