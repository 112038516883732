import React from 'react';

import CropperCustom from './CropperCustom';

const CropImage = ({ selectedFrame, preview, onCropComplete, onFrameCropComplete, isChecked, isResetFrame, setIsResetFrame, setFrameRotation }) => {
  return (
    <div id="crop-area" style={{ position: 'relative', width: '100%', height: '100%' }}>
      <CropperCustom
        image={preview}
        onCropComplete={onCropComplete}
        rotatable={false}
        minZoom={1}
        isChecked={isChecked === 0}
        isReset={false}
        setIsReset={null}
        setImageRotation={null}
        restrictPosition={true}
      />
      {selectedFrame && (
        <CropperCustom
          image={selectedFrame}
          onCropComplete={onFrameCropComplete}
          rotatable={true}
          minZoom={0.1}
          isChecked={isChecked === 1}
          isReset={isResetFrame === 1}
          setIsReset={setIsResetFrame}
          setImageRotation={setFrameRotation}
          restrictPosition={false}
        />
      )}
    </div>
  );
};

export default CropImage;
