import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-scroll';

import "./Welcome.css";

function Welcome() {
    const { t, i18n } = useTranslation();

    return (
        <div className="Welcome">
            <div className='missions'>
                <h1 className='big'>{t("welcome-text-1")}</h1>
                <h2 className='little'>{t("welcome-text-2")}</h2>
                <Link className='button' to="start" smooth={true} duration={500}>{t("get-started")}</Link>
            </div>
            <div className='path'>
                <div className='inner'>
                    <div className='step'>
                        <img src="https://image.pfpframe.com/web-element/select-your-photo-icon.svg"
                            alt="Select your photo icon"
                        />
                        <p className='right'>{t("step-text-1")}</p>
                    </div>
                    <div className='step'>
                        <p className='left'>{t("step-text-2")}</p>
                        <img src="https://image.pfpframe.com/web-element/select-the-frame-icon.svg"
                            alt="Select the frame icon"
                        />
                    </div>
                    <div className='step'>
                        <img src="https://image.pfpframe.com/web-element/download-prepared-photo-icon.svg" 
                            alt="Download the prepared photo icon"
                        />
                        <p className='right'>{t("step-text-3")}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Welcome;
