import React, { useState, useEffect } from 'react';
import Cropper from 'react-easy-crop';

const CropperCustom = ({
    image,                // Resim
    onCropComplete,       // Resim boyut ve kordinat vektörü döndürür
    rotatable,            // Döndürülebilir mi?
    restrictPosition,     // Çerçeveye sığdırılsın mı?
    minZoom,              // Minimum zoom miktarı
    isChecked,            // Seçildi mi?
    isReset,              // Resetlensin mi ayarlar?
    setIsReset,           // Resetlendiğini döndürür
    setImageRotation      // Resimin döndürülme açısını döndürür
  }) => {

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [ctrlKeyPressed, setCtrlKeyPressed] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Control') {
        setCtrlKeyPressed(true);
      }
    };

    const handleKeyUp = (event) => {
      if (event.key === 'Control') {
        setCtrlKeyPressed(false);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const setTotalRotation = (newRotation) => {
    if(rotatable){
      setRotation(newRotation);
      if(setImageRotation){
        setImageRotation(newRotation);
      }
    }
  };

  const handleCropChange = (newCrop) => {
    if (isChecked && !ctrlKeyPressed) {
      setCrop(newCrop);
    }
  };

  const handleZoomChange = (newZoom) => {
    if (isChecked && !ctrlKeyPressed) {
      setZoom(newZoom);
    }else if(isChecked && ctrlKeyPressed){
      const newRotation = rotation + ((zoom - newZoom) < 0 ? -5 : (zoom - newZoom) === 0 ? 0 : 5);
      setTotalRotation(newRotation);
    }
  };

  const handleRotationChange = (newRotation) => {
    if (isChecked) {
      setTotalRotation(newRotation);
    }
  };

  const [cropSize, setCropSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const updateCropSize = () => {
      const parentElement = document.getElementById('crop-area');
      setCropSize({
        width: parentElement.offsetWidth,
        height: parentElement.offsetHeight,
      });
    };

    updateCropSize();
    window.addEventListener('resize', updateCropSize);

    return () => window.removeEventListener('resize', updateCropSize);
  }, []);

  useEffect(() => {
    if (isReset) {
      setCrop({ x: 0, y: 0 });
      setZoom(1);
      setTotalRotation(0);
      if (setIsReset) {
        setIsReset(false);
      }
    }
  }, [isReset]);

  return (
    <div
      style={{
        pointerEvents: isChecked ? 'auto' : 'none',
        width: '100%',
        height: '100%',
        position: "absolute", top: 0, left: 0
      }}
    >
      <Cropper
        image={image}
        crop={crop}
        zoom={zoom}
        rotation={rotation}
        zoomSpeed={0.1}
        aspect={1}
        cropShape="rect"
        showGrid={false}
        onCropChange={handleCropChange}
        onZoomChange={handleZoomChange}
        onRotationChange={handleRotationChange}
        onCropComplete={onCropComplete}
        objectFit={"cover"}

        restrictPosition={restrictPosition}
        minZoom={minZoom}
        cropSize={cropSize}
      />
    </div>
  );
};

export default CropperCustom;
