import React from 'react';

import "./App.css";

import TopBar from './TopBar';
import AllInOne from './AllInOne';       
import Welcome from './Welcome';

function App() {

  return (
    <div className="App">
      <TopBar />
      <Welcome />
      <AllInOne />
    </div>
  );
}

export default App;
