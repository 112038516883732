import React, { useState,useCallback, useEffect,useRef } from 'react';
import { saveAs } from 'file-saver';

import UploadImage from './uploadImage';
import CropImage from './cropImage';
import FramePopup from './framePopup';

import { useTranslation } from 'react-i18next';

import { Element } from 'react-scroll';

import "./AllInOne.css";

import axios from 'axios';

import Switch from 'react-switch';

let fetchedData = null;

async function fetchInitialFrames() {
  if (!fetchedData) { // Daha önce verinin çekilip çekilmediğini kontrol edin
    try {
      const response = await axios.post('https://api.pfpframe.com/api/frame/get', { start: 0, search: "" });
      if(response.data.status === 1 && response.data.matchData && response.data.end){
        fetchedData = response.data;
      }else{
        fetchedData = {end:0, matchData:[]};
      }
    } catch (error) {
      console.error(error);
    }
  }
};

function AllInOne() {
    const [file, setFile] = useState(null);
    const [preview, setPreview] = useState(null);

    const [selectedFrame, setSelectedFrame] = useState(null);

    const { t, i18n } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);

    const handleRemove = () => {
        setFile(null);
        setPreview(null);
    }

    const [firstFrames, setFirstFrames] = useState(fetchedData || {end:0, matchData:[]});

    useEffect(() => {
      const fetchFrames = async () => {
        if (!fetchedData) { // Eğer veri daha önce çekilmemişse
          await fetchInitialFrames();
        }
        setFirstFrames(fetchedData); // Veriyi state'e set edin
      };
      fetchFrames();
    }, []);

    const popupFocus = useRef(null);
    const $body = document.querySelector('html');

    const enable = () => {
      $body.style.overflowY = 'hidden';
      $body.style.overflowX = 'hidden';
    };

    const disable =() => {
      $body.style.overflowY = 'auto';
      $body.style.overflowX = 'hidden';
    };

    useEffect(() => {
      if (isOpen) {
        enable();
        if (popupFocus.current){
          popupFocus.current.focus();
        }
        
      } else {
        disable();
      }
  
      return () => {
        disable();
      };
    }, [isOpen]);

    const togglePopup = () => {
    setIsOpen(!isOpen);
    };

    const closePopup = (e) => {
    if (e.target.classList.contains('popup-overlay')) {
        setIsOpen(false);
    }
    };

    const handleKeyDown = (e) => {
    if (e.key === 'Escape') {
        setIsOpen(false);
    }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [frameCroppedAreaPixels, setFrameCroppedAreaPixels] = useState(null);
    const [rotation, setRotation] = useState(0);

    const getCroppedImg = async (imageSrc, pixelCrop, rotation = 0) => {
      const image = await createImage(imageSrc);
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      
      // Hesaplanmış döndürme açısını radyanlara çevirin
      const radians = rotation * Math.PI / 180;
    
      // Orijinal görüntünün genişlik ve yüksekliğini alın
      const { width: bBoxWidth, height: bBoxHeight } = image;
    
      // Yeni canvas boyutlarını hesaplayın
      const sin = Math.abs(Math.sin(radians));
      const cos = Math.abs(Math.cos(radians));
      const newWidth = Math.floor(bBoxWidth * cos + bBoxHeight * sin);
      const newHeight = Math.floor(bBoxWidth * sin + bBoxHeight * cos);
    
      canvas.width = newWidth;
      canvas.height = newHeight;
    
      // Canvas'ı merkezde döndürmek için çevirin ve döndürün
      ctx.translate(newWidth / 2, newHeight / 2);
      ctx.rotate(radians);
      ctx.translate(-bBoxWidth / 2, -bBoxHeight / 2);
    
      // Orijinal resmi çizdirin
      ctx.drawImage(image, 0, 0);
    
      // Kırpılmış bölgeyi yeni bir canvas'a alın
      const croppedCanvas = document.createElement('canvas');
      const croppedCtx = croppedCanvas.getContext('2d');
      croppedCanvas.width = pixelCrop.width;
      croppedCanvas.height = pixelCrop.height;
    
      croppedCtx.drawImage(
        canvas,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        pixelCrop.width,
        pixelCrop.height
      );
    
      return new Promise((resolve) => {
        croppedCanvas.toBlob((blob) => {
          resolve(blob);
        }, 'image/png');
      });
    };
    
    
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
      setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const onFrameCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
      setFrameCroppedAreaPixels(croppedAreaPixels);
    }, []);
    
    const createImage = (url) => {
      return new Promise((resolve, reject) => {
        const image = new Image();
        image.onload = () => resolve(image);
        image.onerror = (error) => reject(error);
        image.src = url;
      });
    };

    const toDataURL = url => fetch(url)
      .then(response => response.blob())
      .then(blob => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    }));

    const handleSave = async () => {
      if (!croppedAreaPixels) {
        return;
      }
  
      const croppedImageBlob = await getCroppedImg(preview, croppedAreaPixels);
  
      const croppedImage = await createImage(URL.createObjectURL(croppedImageBlob));

      // Create a new canvas with the same dimensions as the original image
      const image = await createImage(preview);
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = croppedAreaPixels.width;
      canvas.height = croppedAreaPixels.height;
  
      ctx.drawImage(
        croppedImage,
        0,
        0,
        croppedAreaPixels.width,
        croppedAreaPixels.height
      );
  
      if (selectedFrame) {
        const frameDataURL = await toDataURL(selectedFrame);
        const frameCroppedImageBlob = await getCroppedImg(frameDataURL, frameCroppedAreaPixels, rotation);
        const frameCroppedImage = await createImage(URL.createObjectURL(frameCroppedImageBlob));
        //const frameImage = await createImage(frameDataURL);
        ctx.drawImage(
          frameCroppedImage,
          0,
          0,
          croppedAreaPixels.width,
          croppedAreaPixels.height
        );
      }
  
      canvas.toBlob((blob) => {
        saveAs(blob, 'cropped-image-with-frame.png');
      }, 'image/png');
    };

    const [isChecked, setIsChecked] = useState(0);
    const [isResetFrame, setIsResetFrame] = useState(0);

    const handleSetIsCheckedChange = () => {
      const newValue = isChecked === 0 ? 1 : 0;
      setIsChecked(newValue);
    };

    const handleResetFrame = () => {
      setIsResetFrame(1);
    };

    return (
      <Element name="start">
        <div className='all-in-one-big'>
          <div className='all-in-one'>
            <div className='image-container'>
              {!file ? (<UploadImage setFile={setFile} setPreview={setPreview} />) :
                (<CropImage selectedFrame={selectedFrame} preview={preview}
                handleSave={handleSave} onCropComplete={onCropComplete} onFrameCropComplete={onFrameCropComplete}
                isChecked={isChecked} isResetFrame={isResetFrame} setIsResetFrame={setIsResetFrame} setFrameRotation={setRotation}/>)}
            </div>
            <div className='options-container'>
              <div className='options'>
                <div className="options-switch">
                  <span>
                    {t('frame-mode')}
                  </span>
                  <Switch
                    onChange={handleSetIsCheckedChange}
                    checked={isChecked}
                    onColor="#2196F3"
                    offColor="#ccc"
                    onHandleColor="#ffffff"
                    offHandleColor="#ffffff"
                    uncheckedIcon={false}
                    checkedIcon={false}
                    handleDiameter={26}
                    
                    aria-label="Enable Frame Mode"
                    aria-labelledby="switch-label"
                    aria-checked={isChecked}
                    aria-hidden={false}
                  />
                </div>
                <div className='reset-frame' onClick={handleResetFrame}>
                    {t('reset-frame')}
                </div>
                <div className='remove' onClick={handleRemove}>
                    {t('remove-photo')}
                </div>
                <div className='save' onClick={handleSave}>
                    {t('save')}
                </div>
              </div>
              <div className='frames'>
                <div className='text'>
                  {t("select-frame")}
                </div>
                <div className='popup' onClick={togglePopup}>
                  <img src={selectedFrame} alt=""
                    style={{ display: selectedFrame ? 'flex' : 'none' }}
                  />
                </div>
                <div className='popup-overlay' onClick={closePopup}
                  style={{ display: isOpen ? 'flex' : 'none' }}
                  ref={popupFocus} tabIndex={-1}>
                  <div className='popup-content' onClick={(e) => e.stopPropagation()}>
                    <FramePopup selectedFrame={selectedFrame} setSelectedFrame={setSelectedFrame} setIsOpen={setIsOpen} firstFrames={firstFrames} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Element>
    );
  }

  export default AllInOne;