import React from 'react';
import { useDropzone } from 'react-dropzone';

import { useTranslation } from 'react-i18next';
import './i18n';

import "./uploadImage.css";

function UploadImage({setFile, setPreview}){
    const { t, i18n } = useTranslation();

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: (acceptedFiles) => {
            const file = acceptedFiles[0];
            setFile(file);
            setPreview(URL.createObjectURL(file));
        }
    });

    return (
        <div className="upload-image">
            <div {...getRootProps()} className="drag-zone">
                <input {...getInputProps()} />
                <text className='text'>{t("drag-zone-text")}</text>
            </div>
        </div>
    );
}

export default UploadImage;